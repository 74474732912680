<template>
  <div>
    <el-form style="margin-top: 20px">
      <el-form-item v-for="(item, index) in data.items" style="display: block;padding-left: 20px" :key="index" :label="item.name + ':'"
        :prop="item.key">
         <span  style="position: relative" slot="label">
               {{ item.name }}
               <span class="top" v-if="item.is_approve==1" style="position: absolute;top: -10px;
  left: -11px;
    width: 30px;
  height: 12px;
   background: linear-gradient(-50deg, #ff2f37, #ff8c18);
   border-radius: 100px;
    font-size: 10px;
 color: #fff;
    line-height: 11px;
   text-align: center;z-index: 99">new</span>
             </span>
        <img style="width: 188px;height: 245px;" v-if="item.type == 'image' " :src="item.url" alt="">
        <!--      <upImg :banner.sync="data.items[index][item.key]" :tipText="item.tips" v-if="item.type === 'file' && item.fileType == 'picture'">-->
        <!--      </upImg>-->
        <img style="width: 188px;" v-if="item.type === 'file' && item.fileType == 'picture' && item.key !=='img-1'" :src="data.items[index][item.key]">
       <div v-if="item.type === 'file' && item.fileType == 'picture' && item.key =='img-1'">
         <el-image  style="width: 200px;" v-for="i1 in item['img-1']"
                    :src="i1.url" :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" :preview-src-list="[i1.url]"
                    :initial-index="4" fit="cover">
         </el-image>
       </div>
        <div v-if="item.type === 'img' ">
          <el-image  v-if="data.items[index][item.key]" style="width: 200px;"
                     :src="data.items[index][item.key]" :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" :preview-src-list="[data.items[index][item.key]]"
                     :initial-index="4" fit="cover">
          </el-image>
        </div>
        <div v-if="item.type=='category' ">
          <br>
          <div v-if="item.category">公司类型：<span style="margin-right: 10px" v-for="i in item.category.type">{{ typeList.find(j=>j.id==i).name }}</span></div>
          <br>
          <div v-if="item.category">行业：
            <span style="margin-right: 10px" v-for="i in item.category.business">{{ businessList.find(j=>j.id==i).name }}</span>
          </div>
          <br>
          <div v-if="item.category">子行业：
            <span style="margin-right: 10px" v-for="i in item.category.subBusiness">{{ subBusinessList.find(j=>j.id==i).name }}</span>
          </div>
        </div>
        <el-button v-if="item.type === 'file' && item.fileType != 'document' && item.fileType != 'picture'" type="primary" size="small" class=""
                   :disabled="!data.items[index][item.key]">
          <a v-if="data.items[index][item.key]" target="_blank" :href="baseUrl + hostname + data.items[index][item.key]"
             style="color: #fff">
            立即下载
          </a>
          <div v-else>立即下载</div>
        </el-button>
        <el-button v-if="item.type === 'file' && item.fileType == 'document'" type="primary" size="small" class=""
          :disabled="!data.items[index][item.key]">
          <a v-if="data.items[index][item.key]" target="_blank" :href="baseUrl + hostname + data.items[index][item.key]"
            style="color: #fff">
            立即下载
          </a>
          <div v-else>立即下载</div>
        </el-button>
<div v-if="item.type=='checkbox'">
  <span style="margin-right: 10px" v-for="checkbox in data.items[index][item.key]">{{checkbox}}</span>
</div>
        <div v-if="item.type=='select'">
          <span>{{data.items[index][item.key]}} </span>
        </div>
        <div v-if="item.type == 'radio'">
          {{ data.items[index][item.key] }}
        </div>
        <div v-if="item.key == 'imgList'">

          <el-form style="margin-top: 20px">
            <br>

            <el-form-item v-for="(i, idx) in item.imgList" style="display: block;margin-bottom: 30px" :key="idx" :label="i.name + ':'">
              <span  style="position: relative" slot="label">
               {{ i.name }}：
               <span class="top" v-if="i.is_approve==1" style="position: absolute;top: -10px;
  left: -11px;
    width: 30px;
  height: 12px;
   background: linear-gradient(-50deg, #ff2f37, #ff8c18);
   border-radius: 100px;
    font-size: 10px;
 color: #fff;
    line-height: 11px;
   text-align: center;z-index: 99">new</span>
             </span>
              <el-image v-if="item.imgList[idx][i.key]"  style="width: 200px; height: 200px"
                         :src="item.imgList[idx][i.key]" :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" :preview-src-list="[item.imgList[idx].primary_img?item.imgList[idx].primary_img:item.imgList[idx][i.key]]"
                         :initial-index="4" fit="cover">
              </el-image>
              <br>
              <el-button v-if="Number(i.is_approve)" type="primary" @click="handleApprove(i)" :disabled="i.is_approve !=1">
                {{i.is_approve==2?'已通过':i.is_approve==3?'审核不通过':'未审核' }}
              </el-button>
              <div style="color: red" v-if="i.is_approve==3">
                审核不通过原因：{{i.approve_refuse_reason}}
              </div>

            </el-form-item>
          </el-form>
        </div>
        <!--      <upFile  :tip="item.tips" :value.sync="" ></upFile>-->
        <!--      <el-input v-model="data.items[index][item.key]" v-if="item.type == 'input'"-->
        <!--                :placeholder="item.tips"></el-input>-->
        <div v-if="item.type == 'input' || item.type == 'textarea'">{{ data.items[index][item.key] }}</div>
        <!--      <el-input v-model="data.items[index][item.key]" v-if="item.type == 'textarea'" type="textarea" :maxlength="item.wordLimit"></el-input>-->
        <div v-if="item.name == '礼品'">
          <div>{{ item.title }}</div>
          <div class="flex-align">
            <img v-if="item.picture" style="width: 287px;height: 200px;margin-right: 20px" :src="item.picture" alt="">
            <div style="margin-right: 20px">礼品名称：{{ item.giftName }}</div>
            <div style="margin-right: 20px">数量：{{ item.num ? item.num : 0 }}</div>
          </div>
        </div>
        <br>
        <el-button v-if="item.is_approve" type="primary" @click="handleApprove(item)" :disabled="item.is_approve !=1">
          {{item.is_approve==2?'已通过':item.is_approve==3?'审核不通过':'未审核' }}
        </el-button>
        <div style="color: red" v-if="item.is_approve==3">
          审核不通过原因：{{item.approve_refuse_reason}}
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Img from "@/views/enterprise/enterprise_cert_list/img.vue";

export default {
  components: { Img },
  props: {
    data: {
      type: Object,
      default() {
        return {

        }
      },
    },
  },
  data() {
    return {
      typeList:[],
      businessList:[],
      subBusinessList:[],
      form:{
        type:[],
        business:[]
      }

    };
  },
  created() {
    if(this.data.sub=="information"){
      // console.log(this.data.items.find(item=>item.type == "category")?.category)
      this.form=this.data.items.find(item=>item.type == "category")?.category
      console.log(this.form)
      if(this.data.items.find(item=>item?.type == "category")?.category){
        this.getEnv()
        this.getType(1)
        this.getType(2)
      }

    }

  },
  mounted() {
    if(this.data.sub=="information"){
      // console.log(this.data.items.find(item=>item.type == "category")?.category)
      this.form=this.data.items.find(item=>item.type == "category")?.category
      console.log(this.form)
      if(this.data.items.find(item=>item?.type == "category")?.category){
        this.getEnv()
        this.getType(1)
        this.getType(2)
      }

    }
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_IMG_URL
    },
    hostname() {
      return process.env.VUE_APP_STATIC_DIR
    }
  },
  methods: {
    handleApprove(e){
      console.log(e)
      this.$emit('approve',e)
    },
    async getType(val) {
      if (val == 1) {
        const { data } = await this.$http.get(
            `/admin/Exhibition/getEnterpriseCategory`,{params:{typeId:this.form.type.toString(),businessId:0}}
        );
        this.businessList = data.data;
        console.log(this.businessList)
      }
      if (val == 2) {
        const { data } = await this.$http.get(
            `/admin/Exhibition/getEnterpriseCategory`,{params:{typeId:this.form.type.toString(),businessId:this.form.business.toString()}}
        );
        this.subBusinessList = data.data;
      }
    },
    async getEnv() {
      const { data } = await this.$http.get('/admin/Exhibition/getEnterpriseCategory',{params:{typeId:0,businessId:0}})
      this.typeList = data.data;
    },
  },
};
</script>
<style scoped lang="less"></style>